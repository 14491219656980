<template>
  <div class="orderDetailsDemo page-info-content">
    <div class="timeCorrelation">
      <el-descriptions :column="1" :title="detailsObj.cmpName">
        <el-descriptions-item label="询价单号">
          {{
            detailsObj.inquiryNo
          }}
        </el-descriptions-item>
        <el-descriptions-item label="发布时间">
          {{
            detailsObj.modifyTime
          }}
        </el-descriptions-item>
        <el-descriptions-item label="截止时间">
          {{
            detailsObj.deadLine
          }}
        </el-descriptions-item>
      </el-descriptions>
      <div class="closing-date">
        <p v-if="detailsObj.expireDays > -1 && detailsObj.status === 0">
          距离截止日期还剩<span>{{ detailsObj.expireDays }}</span>天
        </p>
        <img
          v-if="detailsObj.status === 0"
          src="@/assets/image/jxz.svg"
          alt=""
        >
        <img v-else src="@/assets/image/yxj.svg" alt="">
      </div>
    </div>
    <!-- 求购商品 -->
    <el-descriptions title="求购商品" :column="3">
      <el-descriptions-item label-class-name="labelHidden" content-class-name="table-descriptions" :span="3">
        <Table
          :item-data="itemData"
          :list-data="detailsObj.tradeWantToBuyGoodsInfoReqs"
          :loading="loading"
        >
          <template #goodsOneCategoryName="{ row }">
            <span>{{
              `${row.goodsOneCategoryName}/${row.goodsTwoCategoryName}`
            }}</span>
          </template>
        </Table>
      </el-descriptions-item>
    </el-descriptions>

    <!-- 买家要求 -->
    <el-descriptions title="买家要求" :column="1">
      <el-descriptions-item label="收货地区">
        {{
          detailsObj.receivingArea
        }}
      </el-descriptions-item>
      <el-descriptions-item label="期望收货日期">
        {{
          detailsObj.expectedReceiptDate
        }}
      </el-descriptions-item>
      <el-descriptions-item label="支付方式">
        {{
          detailsObj.paymentMethodWord }}
      </el-descriptions-item>
      <el-descriptions-item label="求购备注">
        {{
          detailsObj.remarks
        }}
      </el-descriptions-item>
      <el-descriptions-item label="附件">
        <div v-if="detailsObj.images">
          <img
            v-for="item in detailsObj.images.split(',')"
            :key="item"
            class="file-img"
            :src="$fileUrl + item"
            alt=""
          >
        </div>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import Table from '@/components/Table.vue'
import { tradeWantToBuyDetail } from '@/api/shoppingMall'
export default {
  components: { Table },
  data() {
    return {
      listData: [],
      total: 0,
      loading: false,
      dialogVisible: false,
      detailsObj: {
        images: '',
        state: 0
      },
      itemData: [
        { label: '品名', prop: 'goodsOneCategoryName', width: 180 },
        { label: '材质', prop: 'goodsMaterialName', width: 180 },
        { label: '规格', prop: 'goodsSpecName', width: 180 },
        { label: '钢厂/厂家', prop: 'manufactor', width: 180 },
        { label: '含税单价（元/吨）', prop: 'unitPriceIncludingTax', width: 100 },
        { label: '求购数量（吨）', prop: 'quantity', width: 180 }
      ],
      paymentMethodList: [
        { id: '0', label: '先款后货' },
        { id: '1', label: '货到付款' },
        { id: '2', label: '账期支付' }
      ]
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getDetails()
    }
  },
  methods: {
    getDetails() {
      tradeWantToBuyDetail(this.$route.query.id, (res) => {
        this.detailsObj = res.data
        const text = []
        this.paymentMethodList.map((item) => {
          if (res.data.paymentMethod.indexOf(item.id) !== -1) {
            text.push(item.label)
          }
        })
        this.detailsObj.paymentMethodWord = text.toString()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.orderDetailsDemo {
  background-color: #fff;
  .timeCorrelation {
    position: relative;
    h2 {
      font-size: 16px;
      margin-bottom: 12px;
    }
    .closing-date {
      position: absolute;
      top: 68px;
      right: 32px;
      text-align: center;
      // margin-left: 260px;
      margin-top: -30px;
      p {
        margin-bottom: 20px;
        color: #333333;
        font-size: 14px;
        span {
          font-weight: bold;
          color: #35383D;
          padding: 0 10px;
        }
      }
    }
  }

  .file-img {
    width: 170px;
  }
  :deep(.el-descriptions__label) {
    vertical-align: top;
  }
}
/deep/ .labelHidden {
  display: none;
}
/deep/ .table-descriptions {
  width: 100%;
}
</style>
